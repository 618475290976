<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <ckeditor
          :editor="editor"
          v-model="editorData"
          :config="editorConfig"
        ></ckeditor>
      </div>
      <div class="col-12 mt-2">
        <button
          class="btn btn-primary btn-sm float-end"
          @click="saveInfo"
          v-if="isWrite == 1"
        >
          <i class="fa fa-save me-1"></i> Simpan
        </button>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      isWrite: localStorage.getItem("isWrite"),
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "|",
          "outdent",
          "indent",
          "|",
          "blockQuote",
          "insertTable",
          "undo",
          "redo",
        ],
      },
    };
  },
  methods: {
    async getInfo() {
      const data = await axios({
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/konfig-login",
        headers: {
          Accept: "application/json",
        },
      }).then((response) => response.data.data);
      if (data) {
        this.editorData = data.referensi?.INFO_UJIAN;
      }
      console.log(data);
    },
    async saveInfo() {
      Swal.fire({
        icon: "warning",
        title: "Apakah Anda yakin menyimpan data Informasi Ujian ini ?",
        showCancelButton: true,
        confirmButtonText: "Ya, Saya yakin!",
        cancelButtonText: "Batal",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = await axios({
            method: "put",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/configs/INFO_UJIAN",
            data: {
              value: this.editorData,
            },
            headers: {
              Authorization: `Bearer ${localStorage.access_token}`,
            },
          }).then((response) => response.data.data);
          if (data) {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Data berhasil disimpan",
              timer: 2000,
              timerProgressBar: true,
            });
          }
        }
      });
    },
  },
  async mounted() {
    await this.getInfo();
  },
};
</script>

<style></style>
